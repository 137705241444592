import React from "react"
import styled from "styled-components"

import Layout from "../../theme/layout"
import SEO from "../../components/header/seo"
import RelatedArticles from "../../components/blog/related-articles"

const BlogPage = ({ pageContext, location }) => {
  return (
    <Layout
      pathContext={pageContext}
      location={location}
      bannerDetails={{
        title: pageContext.title,
        subTitle: pageContext.read_time,
        description: pageContext.excerpt,
        image: pageContext.thumbnail.source_url,
        theme: "dark",
        italic: `by ${pageContext.author} · ${pageContext.date}`,
      }}
    >
      <SEO yoast={pageContext.seo} />
      <Container>
        <BlogContent
          dangerouslySetInnerHTML={{ __html: pageContext.content }}
        ></BlogContent>
        <RelatedArticles
          categories={pageContext.categories}
          currentBlogSlug={pageContext.slug}
        ></RelatedArticles>
      </Container>
    </Layout>
  )
}

export default BlogPage

const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 50px;
  padding: 0 10px;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    flex-direction: column;
    align-items: center;
  }
`

const BlogContent = styled.div`
  p,
  figure,
  h1,
  h2 {
    margin-top: 1.8rem;
  }

  p {
    text-align: justify;
  }

  blockquote {
    background-color: ${({ theme }) => theme.colors.brandGrey};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 10% 30px 10%;
    margin: 50px 0 50px 0;

    & > p {
      margin-top: 0;
      text-align: center;
    }

    & > cite {
      margin-top: 10px;
      font: normal normal bold 21px/35px Poppins;
    }
  }

  .wp-block-column {
    & > div {
      display: inline-block;
    }

    @media (max-width: ${({ theme }) => theme.breakTiny}) {
      align-items: center;
    }
  }

  iframe {
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      width: 100%;
    }
  }
`
