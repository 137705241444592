import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import CloudflareImage from "../reusable/cloudflare-image"

const RelatedArticles = ({ categories, currentBlogSlug }) => {
  const blogQueryData = useStaticQuery(BLOG_QUERY)
  const blogs = blogQueryData?.allSeaBlog?.nodes

  const [blogsToDisplay, setBlogsToDisplay] = useState(blogs)

  useEffect(() => {
    let relatedBlogs = []

    blogs.forEach((blog) => {
      categories.forEach((cat) => {
        blog.categories.every((blogCat) => {
          if (cat.slug == blogCat.slug && currentBlogSlug != blog.slug) {
            relatedBlogs.push(blog)
            return false
          }
        })
      })
    })

    if (relatedBlogs.length == 0) {
      blogs.forEach((blog) => {
        if (currentBlogSlug != blog.slug) {
          relatedBlogs.push(blog)
        }
      })
    }

    setBlogsToDisplay(relatedBlogs)
  }, [])

  return (
    <Container>
      <Title>Related Articles</Title>
      {blogsToDisplay.map((blog, index) => (
        <Link to={`/blog/${blog.slug}`} key={index}>
          <Article>
            <ArticleImageContainer>
              <CloudflareImage
                src={blog.thumbnail.source_url}
                alt={blog.author}
                objectFit="cover"
              />
            </ArticleImageContainer>
            <ArticleInfoContainer>
              <ArticleTitle>{blog.title}</ArticleTitle>
              <ReadTime>{blog.read_time}</ReadTime>
              <ArticleAuthor>
                by {blog.author} &middot; {blog.date}
              </ArticleAuthor>
            </ArticleInfoContainer>
          </Article>
        </Link>
      ))}
    </Container>
  )
}

export default RelatedArticles

const Container = styled.div`
  width: 400px;
  min-width: 400px;
  margin-left: 50px;
  margin-top: 25px;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    width: 300px;
    min-width: 300px;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin-left: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`

const Title = styled.h2`
  margin-bottom: 30px;
  width: 100%;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    font-size: 1.75rem;
    line-height: 2.6rem;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    text-align: center;
  }
`

const Article = styled.div`
  width: 100%;
  height: 400px;
  box-shadow: 0px 3px 20px #00000014;
  border-radius: 8px 8px 8px 8px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    height: 300px;
    width: 300px;
  }
`
const ArticleImageContainer = styled.div`
  width: 100%;
  height: 280px;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    height: 200px;
  }
`

const ArticleInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;
  padding: 0 50px;
  justify-content: space-between;
  padding: 5px 5% 10px 5%;
`

const ArticleTitle = styled.h4`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ReadTime = styled.p`
  color: ${({ theme }) => theme.colors.brandHover};
  text-align: center;
  margin: 0;
`

const ArticleAuthor = styled.p`
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.lightgrey5};
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

// ===============
//      QUERY
// ===============
const BLOG_QUERY = graphql`
  {
    allSeaBlog {
      nodes {
        author
        slug
        title
        date
        read_time
        categories {
          slug
          name
        }
        thumbnail {
          source_url
        }
      }
    }
  }
`
